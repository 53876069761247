<template>
  <div class="content">
    <HomePageForm />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Page index
 * ==================================================================================
 **/

import { mapActions, mapMutations } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import HomePageForm from '@/views/Home/Settings/Content/components/HomePageForm'

export default {
  components: {
    HomePageForm,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.getHomePage()
  },

  destroyed() {
    this.clearHomePageDetails()
  },

  methods: {
    ...mapActions({
      getHomePageDetails: 'settings/getHomePageDetails',
    }),

    ...mapMutations({
      clearHomePageDetails: 'settings/clearHomePageDetails',
    }),

    async getHomePage() {
      if (this.loading) return

      this.loading = true
      await this.getHomePageDetails()
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.content {
  //
}
</style>
