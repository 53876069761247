<template>
  <div class="home-page-form">
    <v-form ref="form" class="home-page-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <HomePageBannerUpload class="home-page-form__banner mb-4" />

      <label class="text-field-label">Title</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        placeholder="Header"
        v-model="form.title"
        :error-messages="form.$getError('title')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-text-field>

      <label class="text-field-label">Subtitle</label>
      <v-text-field
        flat
        solo
        required
        class="mt-2"
        placeholder="Subtitle"
        v-model="form.subtitle"
        :error-messages="form.$getError('subtitle')"
        :loading="form.$busy"
        :disabled="form.$busy"
      ></v-text-field>

      <div class="mt-4">
        <v-btn
          v-if="hasUpdatePermission"
          type="submit"
          color="primary"
          class="mr-4 px-6"
          height="40px"
          :width="buttonWidth"
          :loading="form.$busy"
          >{{ buttonLabel }}</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Building Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import HomePageBannerUpload from '@/views/Home/Settings/Content/components/HomePageBannerUpload'
import Form from '@/utils/form'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    HomePageBannerUpload,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      form: new Form({
        title: '',
        subtitle: '',
      }),
    }
  },

  computed: {
    ...mapState({
      homePage: (state) => state.settings.homePageDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return 'Home Page'
    },

    buttonLabel() {
      return 'Update'
    },

    buttonWidth() {
      return '100%'
    },

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.SETTINGS_UPDATE], this.permissions)
    },
  },

  watch: {
    homePage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.initForm()
      }
    },
  },

  methods: {
    ...mapActions({
      updateHomePage: 'settings/updateHomePage',
    }),

    initForm() {
      if (this.homePage) {
        this.form.title = this.homePage.title
        this.form.subtitle = this.homePage.subtitle
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      await this.updateHomePage(this.getFormData())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Home page content successfully updated!')
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    getFormData() {
      let form = this.form.$data()
      return form
    },
  },
}
</script>
<style lang="scss">
.home-page-form {
  &__form {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
